import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

console.info('What are you looking for in the console ?')
document.querySelector('span.year').textContent = new Date().getFullYear().toString()

const msnry = new Masonry('.msnry', {
  itemSelector: '.project',
  transitionDuration: 0
})

imagesLoaded('.msnry').on('progress', () => msnry.layout())

class Project {
  public images: HTMLElement[]

  constructor (project: HTMLElement) {
    this.images = [project.querySelector('.project__image img'), ...[...project.querySelectorAll('.project__other-images img') as any]]
  }
}

class ProjectsManager {
  private projects: Project[] = []
  private slideshow: HTMLElement
  private currentProject: Project
  private imgIndex: number = 0

  constructor (slideshow, projects: HTMLElement[]) {
    this.slideshow = slideshow
    projects.forEach(project => {
      this.projects.push(new Project(project))
    })

    this.projects.forEach(project => {
      project.images[0].addEventListener('click', () => {
        this.openProject(project)
      })
    })

    window.addEventListener('keydown', e => {
      if (e.key == 'Escape') this.closeProjects()
      if (e.key == 'ArrowRight' && this.currentProject) this.nextSlide()
      if (e.key == 'ArrowLeft' && this.currentProject) this.prevSlide()
    })

    slideshow.addEventListener('click', e => {
      if (e.target.classList.contains('slideshow') && e.target.classList.contains('open')) this.closeProjects()
      else if (e.target.tagName.toLowerCase() === 'img') {
        if (e.clientX < window.innerWidth * .45) this.prevSlide()
        if (e.clientX > window.innerWidth * .55) this.nextSlide()
      }
    })
  }

  private openProject (project: Project) {
    this.closeProjects()
    this.currentProject = project
    this.imgIndex = 0
    this.slideshow.querySelector('.images .number .n').textContent = this.currentProject.images.length.toString()
    this.slideshow.querySelector('.images .number .i').textContent = (this.imgIndex + 1).toString()
    const gallery = this.slideshow.querySelector('.images img')
    gallery.setAttribute('src', project.images[this.imgIndex].getAttribute('src'))
    gallery.setAttribute('alt', project.images[this.imgIndex].getAttribute('alt'))
    this.slideshow.classList.add('open')
    document.body.style.overflowY = 'hidden'
  }

  private closeProjects () {
    this.slideshow.classList.remove('open')
    document.body.style.overflowY = 'auto'
    this.currentProject = null
  }

  private nextSlide () {
    const gallery = this.slideshow.querySelector('.images img')
    this.imgIndex = (this.imgIndex + 1) % this.currentProject.images.length
    if (this.imgIndex === -1) this.imgIndex = this.currentProject.images.length - 1
    this.slideshow.querySelector('.images .number .i').textContent = (this.imgIndex + 1).toString()
    gallery.setAttribute('src', this.currentProject.images[this.imgIndex].getAttribute('src'))
    gallery.setAttribute('alt', this.currentProject.images[this.imgIndex].getAttribute('alt'))
  }

  private prevSlide () {
    const gallery = this.slideshow.querySelector('.images img')
    this.imgIndex = (this.imgIndex - 1) % this.currentProject.images.length
    if (this.imgIndex === -1) this.imgIndex = this.currentProject.images.length - 1
    this.slideshow.querySelector('.images .number .i').textContent = (this.imgIndex + 1).toString()
    gallery.setAttribute('src', this.currentProject.images[this.imgIndex].getAttribute('src'))
    gallery.setAttribute('alt', this.currentProject.images[this.imgIndex].getAttribute('alt'))
  }
}

const projectDetails = new ProjectsManager(
  document.querySelector('.slideshow'),
  [...document.querySelectorAll('#work .project')] as HTMLElement[]
)
